$brand: tmi;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

.md-textfield-wrapper--phone {
  margin-bottom: $gap-large;
  position: relative;

  .error-container {
    margin-top: $gap-small;
  }

  .text-field-inline-tick {
    color: $green;
    position: absolute;
    right: 0;
    top: 5px;
    opacity: 0;
    transition: opacity 0.15s;
    &.showing {
      opacity: 1;
    }
  }

  .intl-tel-input {
    display: flex;
    align-items: center;
    .selected-flag {
      background-color: transparent !important;
      outline: 1px dotted transparent;
      &:focus {
        outline-color: $secondary;
      }
    }
    .md-text-field {
      border: none;
      border-bottom: 1px solid $grey;
      border-radius: 0 !important;
      padding: 6px 0 !important;
      margin-left: $gap-small !important;
      &:focus {
        padding-bottom: 5px !important;
        border-bottom: 2px solid $primary;
      }
    }
    .flag-container {
      width: auto !important;
      position: relative;
      height: 36px;
    }
    .selected-flag {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}

.md-text-field-container {
  .md-text--disabled {
    color: rgba(0, 0, 0, 0.54); // Label color on disabled fields same as for non-disabled ones.
  }

  .md-floating-label--floating {
    font-weight: 600;
  }

  input.md-text-field {
    color: $md-text-field-color;
  }

  input.md-text--disabled {
    color: $md-primary-color !important;
    opacity: 1 !important; // Safari makes disabled inputs semi-transparent.
  }

  input:focus + hr + .md-text--disabled {
    height: auto;
  }

  .md-divider {
    background-color: $grey;
    &:after {
      height: 1px;
    }
  }

  input.md-text--disabled + .md-divider {
    background-color: $grey-light;
    &:after {
      height: 1px;
    }
  }

  .md-floating-label--inactive + input:-webkit-autofill {
    // Chrome issue https://bugs.chromium.org/p/chromium/issues/detail?id=636425 (also applies to email field).
    // Workaround is to hide text in autofilled fields until value appears (when page is interacted with).
    visibility: hidden;
  }

  .md-text-field-inline-indicator.md-password-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Overriding react-md media queries here:
  @media screen and (min-width: 320px) {
    .md-text-field-inline-indicator--floating {
      top: 36px;
    }
  }

  @media screen and (min-width: 1025px) {
    .md-floating-label--inactive-sized {
      font-size: rem(16px);
    }

    .md-text-field-inline-indicator.md-password-btn {
      width: rem(30px);
      height: rem(30px);

      &:focus {
        outline-offset: -3px;
      }
    }

    .md-floating-label--inactive {
      transform: translate3d(0, 36px, 0);
    }

    .md-text-field-inline-indicator--floating {
      top: 32px;
    }
  }

  // Password visibility toggle button:
  .md-text-field-inline-indicator.md-password-btn:not(.md-password-btn--invisible):not(.md-text--disabled) {
    color: $md-secondary-color;
  }

  .md-password-btn--invisible::before,
  .md-password-btn--invisible::after {
    display: none;
  }

  .md-password-btn:focus {
    outline: 1px dotted $md-secondary-color;
  }

  .md-text-field-message-container--right-icon-offset {
    padding-right: 0 !important;
  }

  .text-field-inline-tick.md-icon {
    color: $green;
    opacity: 0;
    transition: opacity 0.15s;

    &.showing {
      opacity: 1;
    }
  }
}

.md-textfield-wrapper {
  .error-container:empty {
    // Fixes bug where wrapper height increased slightly when the field was invalid, even if no error was displayed.
    display: none;
  }
  .right-label {
    font-size: rem(12px);
    position: absolute;
    right: 24px;
    top: 8px;
    color: $text-grey-small-on-white;
  }
  .right-label.hide {
    display: none;
  }
}
