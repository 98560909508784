@import './mixins';

@if ($brand == 'tmi') {
  // colors
  $md-primary-color: #1d479b;
  $md-primary-color-darker: #1d479b;
  $md-secondary-color: #3b71dc;
  $md-secondary-color-lighter: rgba(59, 113, 220, 0.1);
  $primary: $md-primary-color;
  $primary-transparent: rgba(26, 18, 77, 0.5);
  $secondary: $md-secondary-color;

  $grey: #ccc;
  $yellow: #f9af2c;
  $green: #00bc82;
  $dark-green: #007f58;
  $red: #e34647;
  $pale-red: rgba(173, 24, 64, 0.8);
  $orange: #f9af2c;
  $black: #333;
  $white: #fff;
  $grey-light: #efefef;
  $grey-lightest: #f8f8f8;
  $text-grey: #58595b;
  $text-grey-on-grey: #6d6d6d;
  $text-grey-small-on-white: #767676;

  $price-color: $md-secondary-color;

  $text-color: #58595b;

  $primary-icon-color: $md-primary-color;

  $primary-button-background-color: $md-secondary-color;
  $primary-button-border-color: $md-secondary-color;
  $primary-button-text-color: $white;

  $primary-outline-button-background-color: $white;
  $primary-outline-button-border-color: $md-primary-color-darker;
  $primary-outline-button-text-color: $md-primary-color-darker;
  $primary-outline-button-background-color--active: $md-primary-color-darker;
  $primary-outline-button-border-color--active: $md-primary-color-darker;
  $primary-outline-button-text-color--active: $white;

  $primary-radiobutton-background-color--active: $md-primary-color-darker;
  $primary-radiobutton-border-color--active: $md-primary-color-darker;
  $primary-radiobutton-text-color--active: $white;

  $tertiary-outline-button-background-color: $yellow;
  $tertiary-outline-button-border-color: $yellow;
  $tertiary-outline-button-text-color: #333;
  $tertiary-outline-button-background-color--active: $md-primary-color-darker;
  $tertiary-outline-button-border-color--active: $md-primary-color-darker;
  $tertiary-outline-button-text-color--active: $white;

  $save-for-late-button-background-color: $primary;
  $save-for-late-button-hover-background-color: $secondary;

  $radio-button-link-color: $primary;
  $radio-button-link-hover-color: $secondary;

  $md-selection-control-button-color: $md-secondary-color;

  $tippy-font-color: #333;

  $body-font-weight: normal;
  $body-font-weight-light: 300;
  $button-font-weight: 500;
  $md-text-font-weight: 500;
  $md-autocomplete-font-weight: 500;
  $md-autocomplete-textfield-font-weight: 500;
  $date-picker-font-weight: 500;
  $page-heading-2-font-weight: bold;
  $item-pricing-font-weight: 500;

  $heading1-color: #333;
  $heading2-color: #333;
  $heading3-color: #333;
  $heading4-color: #333;
  $heading5-color: #333;

  $date-picker-color: $secondary;

  $heading-highlight-bar-color: $green;

  $heading3-custom-background: transparent;
  $heading3-custom-font-color: $green;
  $heading4-custom-background: transparent;
  $heading4-custom-font-color: #333;

  $expand-link-bg: $md-secondary-color-lighter;

  $header-background: $white;
  $body-background: $white;
  $link-color: $md-secondary-color;
  $link-hover-color: $md-secondary-color;
  $quote-footer-color: $yellow;
  $portal-link-yellow-font-color: #333;
  $billing-summary-card-header-billing-background-color: #cbdcff;
  $billing-summary-card-header-color: #333;

  $estimate-panel-floating-border-color: $green;
  $profile-indicator-color: $md-primary-color-darker;

  $estimate-panel-background-color: $white;
  $estimate-panel-border-color: $grey;
  $bundle-builder-toast-background-color: $md-secondary-color;
  $bundle-builder-banner-background-color: $md-primary-color-darker;
  $bundle-builder-banner-separator-color: $md-secondary-color;
  $bundle-builder-banner-title-color: $yellow;

  $stepper-bg: $primary;
  $stepper-bundle-label-color: $md-secondary-color;
  $stepper-icon-bg: $white;
  $stepper-icon-border-color: white;
  $stepper-icon-color: $primary;
  $stepper-label-color: $white;
  $stepper-line-color: $white;

  $stepper-complete-icon-bg: $green;
  $stepper-complete-icon-border-color: $green;
  $stepper-complete-icon-color: $white;
  $stepper-complete-label-color: $white;
  $stepper-complete-line-color: $white;

  $stepper-complete-hover-icon-bg: $green;
  $stepper-complete-hover-icon-border-color: $green;
  $stepper-complete-hover-icon-color: $grey;

  $stepper-active-icon-bg: $green;
  $stepper-active-icon-border-color: $green;
  $stepper-active-icon-color: $white;
  $stepper-active-label-color: $white;
  $stepper-active-line-color: $white;

  $most-popular-bg-color: $yellow;
  $most-popular-text-color: $primary;

  $policy-summary-title-flag-color: $dark-green;

  // fonts
  $font-1: 'Story Sans', sans-serif;
  // $font-2: Helvetica, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  $md-font-family: 'Story Sans', sans-serif;
  $md-error-color: $red;
  $md-btn-text-border-radius: 4px;
  $md-font-size-base: 16;
  $md-btn-mobile-font-size: rem(16px);
  $md-btn-desktop-font-size: rem(18px);
}
