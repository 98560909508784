$brand: tmi;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

.question {
  margin-bottom: $gap-question;
  margin-left: 40px;
  margin-right: 0;
  position: relative;
  @include tablet {
    margin-left: 55px;
    width: $question-width;
    max-width: 100%;
  }

  @include desktop {
    margin-left: 0;
  }

  &:last-child {
    margin-bottom: 0;

    .question-vertical-line {
      display: none;
    }
  }

  .question-vertical-line {
    content: '';
    background: $grey;
    position: absolute;
    top: 70px;
    bottom: -115px;
    left: -30px;
    width: 1px;
    @include tablet {
      left: -35px;
    }
    @include desktop {
      left: -55px;
    }
  }

  &.question--fullwidth {
    @include desktop {
      width: $question-width-tablet;
    }
    @include large-desktop {
      width: $question-full-width;
      .md-radiobutton-container .md-radiobutton--wrapper {
        flex-grow: 1;
        // .md-radiobutton {
        //   flex-grow: 1;
        // }
      }
    }
  }

  &.question--subquestion {
    margin-top: calc(-#{$gap-question} + #{$gap-question-subquestion});
    .question-vertical-line {
      top: 70px;
      height: 100%;
    }
    .question-tick {
      display: none;
    }
  }

  &.question--subquestion + .question--subquestion .question-vertical-line {
    top: 30px;
    height: 110%;
  }

  &.question--headline-block {
    margin-bottom: 0;
    padding-bottom: $gap-question;
    position: relative;
    .question-vertical-line {
      top: -110px;
      bottom: 0px;
    }

    &.question--subheading {
      padding-bottom: $gap-question-subquestion;
    }

    h2 {
      margin-bottom: $gap-xxlarge;
    }
    ul {
      padding-left: $gap-large;
    }
    ul > li {
      margin-bottom: $gap-small;
    }

    p {
      overflow: hidden;
    }
    span,
    .tooltip-icon,
    .tooltip-icon > div {
      display: inline;
    }

    .tooltip-icon > div > a {
      position: relative;
      top: 5px;
      left: 5px;
      overflow: hidden;
      display: inline;
    }
  }

  // force tick to be statically placed
  &.question--redbookReference .question-tick {
    top: 75px;
  }

  &.question--addressDetails .question-tick {
    top: 35px;
  }

  &.question--tooltip .question-title {
    display: block;
    > h3 {
      display: inline;
      margin-right: $gap-xsmall;
    }
  }
  &.question--tooltip .tooltip-icon {
    display: inline-block;
    min-width: 23px;
    min-height: 23px;
    > div,
    > div > a {
      display: inline !important;
    }
    > div > a {
      vertical-align: sub;
      position: absolute !important;
      top: inherit !important;
      left: inherit !important;
      right: inherit !important;
    }
  }

  .question-title {
    position: relative;
    display: flex;
    // padding-right: $gap-xlarge;
    margin-bottom: $gap-normal;
    overflow: hidden;
    padding-bottom: 2px;
  }

  .question-title h3 {
    position: relative;
    white-space: pre-wrap;
    margin-bottom: 0;
    @include desktop {
      width: 100%;
    }
  }

  p.question-subtext {
    font-size: rem(16px);
    line-height: rem(24px);
    margin-bottom: $gap-normal;
  }

  p.question-additional-info {
    font-size: rem(16px);
    line-height: rem(24px);
    margin-top: $gap-normal;
    margin-bottom: $gap-normal;
    display: flex;
    align-items: center;
    .md-icon {
      color: $primary;
    }
  }
  .tooltip-container .tippy-popper {
    padding: 0;
    margin: 0;
    max-width: 100%;
    box-shadow: none;
    .tooltip-icon-close {
      top: 0;
      right: 0;
    }
  }
  .tippy-popper [data-state='visible'] {
    display: block;
    padding-right: 25px;
    margin-bottom: $gap-large;
  }
}
