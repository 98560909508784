@import './variables';
@import './media-queries';
html {
  font-size: 100%;
}
// typography
body {
  color: $text-color;
  font-weight: $body-font-weight;
  font-size: rem(16px);
  line-height: rem(22px);

  .primary-heading {
    color: $md-primary-color;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
    white-space: pre-wrap;

    &.highlight-bar::after {
      content: ' ';
      border-bottom: $border-width-xxthick solid $heading-highlight-bar-color;
      width: 34px;
      height: 0;
      margin-top: $gap-normal;
      position: absolute;
      display: block;
    }
  }

  h1,
  .md-title,
  .heading1 {
    color: $heading1-color;
    font-size: rem(24px);
    font-weight: $body-font-weight-light;
    line-height: rem(29px);
    letter-spacing: 1pt;
  }

  h2,
  .md-subheading-1,
  .heading2 {
    color: $heading2-color;
    font-weight: $body-font-weight-light;
    font-size: rem(38px);
    line-height: rem(45px);
    @include tablet {
      font-size: rem(48px);
      line-height: rem(56px);
    }
  }

  .md-subheading-2,
  .heading3,
  h3 {
    color: $heading3-color;
    font-size: rem(30px);
    line-height: rem(37px);
    font-weight: $body-font-weight-light;
    @include tablet {
      font-size: rem(38px);
      line-height: rem(45px);
    }
  }

  h4,
  .heading4 {
    color: $heading4-color;
    font-size: rem(28px);
    line-height: rem(35px);
    font-weight: $body-font-weight-light;
    @include tablet {
      font-size: rem(38px);
      line-height: rem(45px);
    }
  }

  h5,
  .heading5 {
    color: $heading5-color;
    font-size: rem(22px);
    line-height: rem(26px);
    font-weight: bold;
  }

  p,
  a,
  .md-body-1,
  .link,
  .md-text,
  .md-text-field {
    color: $text-color;
    font-weight: $body-font-weight;
    font-size: rem(16px);
    line-height: rem(22px);
  }

  .md-text,
  .md-text-field {
    font-weight: $md-text-font-weight;
  }

  .md-selection-control-label.md-text--disabled > span {
    font-size: rem(18px);
    font-weight: 600;
  }

  a,
  .link {
    text-decoration: none;
    border-bottom: 1px solid;
    color: $link-color;
    &.expand-link {
      border-bottom-style: dotted;
    }
    &.no-border {
      border-bottom: none;
    }
    > .md-icon {
      color: $link-color;
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      border-bottom-color: transparent;
      color: $link-hover-color;

      > .md-icon {
        color: $link-hover-color;
      }
    }
    &:focus {
      outline-color: $link-hover-color;
      outline-style: dotted;
      outline-width: 1px;
      outline-offset: 3px;
    }
  }

  .link--icon {
    display: inline-flex;
    align-items: center;

    a,
    i {
      color: $link-color;
    }
    i.md-icon {
      font-size: rem(18px);
      margin-left: $gap-xsmall;
    }
    a {
      &:hover,
      &:focus,
      &:active {
        color: $md-secondary-color;

        + i {
          color: $md-secondary-color;
        }
      }
    }
  }

  a[target='_blank'] {
    position: relative;
    margin-right: 23px;
    &::after {
      font-family: 'Material Icons';
      content: 'launch';
      position: absolute;
      padding-left: 5px;
      right: inherit;
    }
  }

  .md-text,
  .md-text-field {
    font-size: rem(18px);
    line-height: rem(24px);
  }

  small,
  .small {
    font-weight: 600;
    font-size: rem(14px);
    line-height: rem(22px);
  }

  .very-small {
    font-weight: 600;
    font-size: rem(13px);
    line-height: rem(18px);
  }

  b,
  .bold {
    font-weight: bold;
  }

  .footnote {
    @extend small;
    color: $text-grey-on-grey;
    display: table;
    &::before {
      content: '*';
      display: table-cell;
      padding-right: 0.5rem;
    }

    a {
      color: $link-color;
      font-size: inherit;
      font-weight: 600;
    }
  }

  .price {
    color: $price-color;
    .symbol {
      color: $md-primary-color;
      font-weight: $body-font-weight;
    }
  }

  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
}
