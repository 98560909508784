$brand: tmi;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

.header-main {
  width: 100%;
  background-color: $header-background;

  .header-content {
    display: flex;
    align-items: center;
    margin: auto;
    padding: $gap-large $gap-xxlarge;
    max-width: $boxed-width;
    position: relative;

    h1 {
      margin: 0 0 6px $gap-enormous;
      color: $header-font-color;

      display: none;
      @include tablet {
        display: block;
        @include brand(air) {
          overflow: inherit;
        }
      }

      @include brand(air) {
        display: block;
        visibility: visible;
        clip: unset;
        height: auto;
        width: auto;
        position: relative;
      }
    }

    .logo,
    .logo-tsb {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 142px;
      height: 53px;
      text-indent: -9999px;
      flex-shrink: 0;
      @include brand(twr, air, tsb) {
        background-image: url('~assets/logo/twr-logo.png');
        margin: 2px -0 -2px 0; // Align Tower logo with header text.
      }
      @include tmi {
        width: 142px;
        background-image: url('~assets/logo/tmi-logo.png');
      }
    }

    .logo-tsb {
      width: 142px;
      height: 53px;
      background-image: url('~assets/logo/tsb-logo.png');
      position: relative;
      margin-right: 50px;
      @include tablet {
        margin-right: 60px;
      }
    }

    .logo-spacer {
      left: 157px;
      position: absolute;
      font-size: 40px;
      background: $grey;
      height: 40px;
      width: 1px;
      @include tablet {
        left: 175px;
      }
      @include desktop {
        left: 200px;
      }
    }

    .header-links {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }

    .header-links a {
      margin-right: $gap-large;
      color: $md-secondary-color;
      text-decoration: none;
      font-weight: 600;
      border-bottom: none;
      display: none;
      @include desktop {
        display: flex;
      }
      @include tsb {
        color: $white;
      }

      &:hover,
      *:focus {
        text-decoration: underline;
      }
    }
    .header-links #headerLogoutLink {
      margin-right: 0;
    }

    #headerLogoutClaimsLink {
      display: block;
    }

    @include up-to-desktop {
      padding: $gap-normal $gap-xxlarge;
      box-shadow: $shadow-medium;

      .logo {
        width: 120px;
        height: 45px;

        @include twr {
          margin: 0;
        }
      }

      .portal .header-link {
        display: none;
      }
    }

    @include up-to-tablet {
      padding: $gap-small $gap-normal;
    }

    @include tiny-phone {
      .logo {
        width: 100px;
        height: 40px;
      }
    }
  }
}
