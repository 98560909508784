//NOTE: no min-width for phone...we are going 'phone-first/mobile-first' so our default rules should
//be for small screens
$phone-min: 0;
$phablet-min: 576px;
$tablet-min: 768px;
$desktop-min: 1024px;
$large-desktop-min: 1280px;
$x-large-desktop-min: 1600px;
$tiny-phone-max: 340px;

@mixin phablet {
  @media (min-width: $phablet-min) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-min) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-min) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: $large-desktop-min) {
    @content;
  }
}

@mixin x-large-desktop {
  @media (min-width: $x-large-desktop-min) {
    @content;
  }
}

@mixin phone-only {
  @media (max-width: $phablet-min) {
    @content;
  }
}

@mixin phablet-only {
  @media (min-width: $phablet-min) and (max-width: $tablet-min) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: $tablet-min) and (max-width: $desktop-min) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: $desktop-min) and (max-width: $large-desktop-min) {
    @content;
  }
}

@mixin large-desktop-only {
  @media (min-width: $large-desktop-min) and (max-width: $x-large-desktop-min) {
    @content;
  }
}

@mixin min-max-screen-width($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin up-to-tablet {
  @media (max-width: #{$tablet-min - 1}) {
    @content;
  }
}

@mixin up-to-desktop {
  @media (max-width: #{$desktop-min - 1}) {
    @content;
  }
}

@mixin tiny-phone {
  @media (max-width: $tiny-phone-max) {
    @content;
  }
}
