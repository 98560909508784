@import './mixins';

// colors
$md-primary-color: #020f50 !default;
$md-primary-color-darker: #020f50 !default;
$md-secondary-color: #007acc !default;
$md-secondary-color-lighter: #e5f3f9 !default;
$md-secondary-color-lightest: #f2f9fc !default;
$primary: $md-primary-color !default;
$primary-transparent: rgba(2, 15, 80, 0.5) !default;
$secondary: $md-secondary-color !default;
$grey: #c4c4c4 !default;
$yellow: #ffcf03 !default;
$pale-yellow: #fff5cd !default;
$light-green: #f3f9e9 !default;
$green: #8cc423 !default;
$dark-green: #3a7800 !default;
$red: #c5093b !default;
$pale-red: rgba(197, 9, 59, 0.8) !default;
$orange: #ff9e15 !default;
$dark-orange: #d43900 !default;
$black: #000 !default;
$white: #fff !default;
$blue-lightest: #f2f9fc !default;
$grey-light: #efefef !default;
$grey-lightest: #f8f8f8 !default;
$grey-dark: #ddd !default;
$text-grey: #909090 !default;
$text-grey-on-grey: #00020e !default;
$text-grey-small-on-white: #767676 !default;

$price-color: $md-secondary-color !default;

$text-color: $primary !default;

$primary-icon-color: $primary !default;
$secondary-icon-color: $secondary !default;

$primary-button-background-color: $md-primary-color !default;
$primary-button-border-color: $md-primary-color !default;
$primary-button-text-color: $white !default;

$primary-outline-button-background-color: transparent !default;
$primary-outline-button-border-color: $md-primary-color !default;
$primary-outline-button-text-color: $md-primary-color !default;
$primary-outline-button-background-color--active: $md-primary-color !default;
$primary-outline-button-border-color--active: $md-primary-color !default;
$primary-outline-button-text-color--active: $white !default;

$primary-radiobutton-background-color--active: $md-secondary-color !default;
$primary-radiobutton-border-color--active: $md-secondary-color !default;
$primary-radiobutton-text-color--active: $white !default;

$tertiary-outline-button-background-color: $yellow !default;
$tertiary-outline-button-border-color: transparent !default;
$tertiary-outline-button-text-color: $primary !default;
$tertiary-outline-button-background-color--active: $md-primary-color !default;
$tertiary-outline-button-border-color--active: transparent !default;
$tertiary-outline-button-text-color--active: $white !default;

$white-button-background-color: $md-secondary-color !default;
$white-button-border-color: $md-secondary-color !default;
$white-button-text-color: $white !default;

$white-outline-button-background-color: $md-secondary-color !default;
$white-outline-button-border-color: $md-secondary-color !default;
$white-outline-button-text-color: $white !default;

$save-for-late-button-background-color: $primary !default;
$save-for-late-button-hover-background-color: $secondary !default;

$radio-button-link-color: $primary !default;
$radio-button-link-hover-color: $secondary !default;

$md-selection-control-button-color: $md-secondary-color !default;

$tippy-font-color: $primary !default;

$body-font-weight: 500 !default;
$body-font-weight-light: 300 !default;
$button-font-weight: 600 !default;
$md-text-font-weight: 600 !default;
$md-autocomplete-font-weight: 600 !default;
$md-autocomplete-textfield-font-weight: 600 !default;
$date-picker-font-weight: 600 !default;
$page-heading-2-font-weight: 300 !default;
$item-pricing-font-weight: 600 !default;

$heading1-color: $primary !default;
$heading2-color: #00020e !default;
$heading3-color: #6a2f77 !default;
$heading4-color: #922800 !default;
$heading5-color: $primary !default;

$date-picker-color: $secondary !default;

$heading-highlight-bar-color: $yellow;

$heading3-custom-background: transparent !default;
$heading3-custom-font-color: $heading3-color !default;
$heading4-custom-background: transparent !default;
$heading4-custom-font-color: $heading4-color !default;

$header-background: $white !default;
$header-font-color: $md-primary-color-darker !default;
$body-background: $white !default;
$link-color: $md-secondary-color !default;
$link-hover-color: $md-secondary-color !default;
$quote-footer-color: #353f73 !default;
$portal-link-background-color: #cce8f5 !default;
$portal-link-yellow-font-color: $primary !default;
$accordion-panel--title-expanded-background-color: #cce8f5 !default;
$billing-summary-card-header-billing-background-color: #cce8f5 !default;
$billing-summary-card-header-color: $primary !default;

$md-text-field-color: $md-secondary-color !default;
$md-autocomplete-item-color: $md-secondary-color !default;

$estimate-panel-floating-border-color: $yellow !default;
$estimate-panel-background-color: $white !default;
$estimate-panel-border-color: $grey !default;
$confirmation-message-background-color: $light-green !default;
$profile-indicator-color: $dark-green !default;
$bundle-builder-toast-background-color: $secondary !default;
$bundle-builder-toast-text-color: $white !default;
$bundle-builder-banner-background-color: $md-primary-color !default;
$bundle-builder-banner-separator-color: $md-primary-color !default;
$bundle-builder-banner-title-color: $white !default;

$expand-link-bg: $pale-yellow !default;

$blocker-border-color: $grey !default;
$blocker-background-color: $grey-lightest !default;
$blocker-heading-color: $grey-lightest !default;
$blocker-refer-border-color: $orange !default;
$blocker-refer-background-color: transparent !default;
$blocker-refer-heading-color: $orange !default;
$blocker-decline-border-color: $red !default;
$blocker-decline-background-color: $grey-lightest !default;
$blocker-decline-heading-color: $red !default;

$bundle-product-picker-label-background-active: $md-secondary-color-lighter !default;
$bundle-product-picker-label-color-active: $md-secondary-color !default;

$construction-estimate-rebuild-background: $md-secondary-color-lightest !default;
$construction-estimate-rebuild-amount-background: $md-secondary-color-lighter !default;
$construction-estimate-rebuild-amount-color: $text-color !default;

$stepper-bg: $grey-light !default;
$stepper-bundle-label-color: inherit !default;
$stepper-icon-bg: $grey-light !default;
$stepper-icon-border-color: $md-primary-color !default;
$stepper-icon-color: $md-primary-color !default;
$stepper-label-color: $md-primary-color !default;
$stepper-line-color: $md-primary-color !default;

$stepper-complete-icon-bg: $grey-light !default;
$stepper-complete-icon-border-color: $md-primary-color !default;
$stepper-complete-icon-color: $md-primary-color !default;
$stepper-complete-label-color: $md-primary-color !default;
$stepper-complete-line-color: $md-primary-color !default;
$stepper-complete-hover-icon-bg: $md-primary-color !default;
$stepper-complete-hover-icon-border-color: $md-primary-color !default;
$stepper-complete-hover-icon-color: $white !default;

$stepper-active-icon-bg: $secondary !default;
$stepper-active-icon-border-color: $secondary !default;
$stepper-active-icon-color: $white !default;
$stepper-active-label-color: $md-primary-color !default;
$stepper-active-line-color: $md-primary-color !default;

$most-popular-bg-color: $yellow !default;
$most-popular-text-color: $primary !default;

$policy-summary-divider-color: $grey !default;
$policy-summary-title-flag-color: $green !default;

// fonts
$font-1: 'rawline', sans-serif !default;
$font-2: Helvetica, Arial, 'Helvetica Neue', Helvetica, sans-serif !default;

// When the primary or secondary colors are not part of material design, you will need to set
// some fallback colors for the the following components:
$md-data-table-contextual-fallback-color: rgba($md-secondary-color, 0.32);
$md-linear-progress-fallback-color: rgba($md-secondary-color, 0.54);
$md-switch-fallback-color: rgba($md-secondary-color, 0.87);
$md-switch-ball-fallback-color: rgba($md-secondary-color, 0.87);
$md-slider-warn-md: false;

// Layout
$boxed-width: 1280px !default;
$compact-width: 400px !default;
$compact-vertical-padding: 100px !default;
$question-width: 608px !default;
$question-width-tablet: 670px !default;
$question-full-width: 870px !default;

// Borders
$border-width-normal: 1px;
$border-width-thick: 2px;
$border-width-xthick: 3px;
$border-width-xxthick: 4px;

// Border Radius
$border-radius-xtight: 1px;
$border-radius-tight: 2px;
$border-radius-normal: 3px;
$border-radius-wide: 4px;
$border-radius-xwide: 5px;
$border-radius-xxwide: 6px;
$border-radius-xxxwide: 7px;

// Measurements
$gap-xxsmall: 3px;
$gap-xsmall: 5px;
$gap-small: 10px;
$gap-normal: 15px;
$gap-large: 20px;
$gap-xlarge: 25px;
$gap-xxlarge: 30px;
$gap-enormous: 35px;
$gap-xenormous: 40px;
$gap-xxenormous: 45px;
$gap-xxxenormous: 50px;
$gap-biggest: 65px;

// custom
$gap-grid: 12px;
$gap-question: 40px;
$gap-question-subquestion: 40px;
$portal-form-field-max-width: 480px;
$gap-question-claim: 60px;
$gap-question-subquestion-claim: 25px;

// react-md
$md-data-table-contextual-fallback-color: rgba($md-secondary-color, 0.32);
$md-linear-progress-fallback-color: rgba($md-secondary-color, 0.54);
$md-switch-fallback-color: rgba($md-secondary-color, 0.87);
$md-switch-ball-fallback-color: rgba($md-secondary-color, 0.87);
$md-slider-warn-md: false;

$md-font-family: $font-1 !default;
$md-error-color: $red !default;
$md-btn-text-border-radius: 3px !default;
$md-font-size-base: 16;
$md-btn-mobile-font-size: rem(16px) !default;
$md-btn-desktop-font-size: rem(18px) !default;

$shadow-medium: 0 4px 12px 0 rgba(0, 0, 0, 0.1) !default;
$shadow-high: 0 4px 12px 0 rgba(0, 0, 0, 0.13) !default;
$overdue-border-color: rgba(197, 9, 59, 0.1) !default;

@import './variables.tmi';
@import './variables.tsb';
@import './variables.twr';
