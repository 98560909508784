@import './variables';
@import './media-queries';

.error-container {
  display: flex;
  flex-direction: column;
  span {
    color: $red;
    font-size: rem(12px);
    line-height: rem(18px);
    font-weight: 600;
  }
}

.error {
  border: 1px solid $red;
  padding: $gap-normal $gap-small;
  margin-bottom: $gap-normal;
  background: $pale-red;
  color: $white;
}

.form-error-container {
  color: $red;
  font-size: rem(14px);
  line-height: rem(18px);
  margin: $gap-small 0;
}

.btn-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  .md-btn {
    max-width: 50%;
    justify-content: flex-start;
    margin-right: $gap-small;
  }

  .md-btn + .md-btn {
    margin-right: 0px;
  }

  &.btn-group-large .md-btn {
    max-width: 100%;
  }
}

.btn-error {
  background: $red;
  &:hover,
  &:focus,
  &:active {
    background: $pale-red;
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
  position: absolute;
  width: 100%;
  height: 100vh;
}

.fade-exit {
  opacity: 1;
  z-index: 0;
  top: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-out;
  position: absolute;
  width: 100%;
  height: 100vh;
}

::-ms-clear {
  display: none;
}
::-ms-reveal {
  display: none;
}
