$brand: tmi;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

#marketing-header {
  margin-left: 25px;
  width: calc(100% - 25px);
  display: none;
  @include desktop {
    display: block;
  }
  #marketing-header-menu {
    float: left;
    > ul {
      padding: 0;
      margin: 0;
    }

    > ul > li {
      display: inline-block;
      list-style: none;
      margin: 0 8px;
      position: relative;
    }

    ul li a {
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 15px 5px;
      color: $md-primary-color;
      font-size: rem(16px);
      border-bottom: none;
      font-weight: 400;
      position: relative;
      margin-right: 0;
      &:before,
      &:after {
        content: none;
      }
      &:hover,
      &:focus {
        + ul li {
          display: block;
        }
        &:after {
          content: '__';
          position: absolute;
          font-family: Arial;
          font-size: 38px;
          font-weight: 600;
          color: $yellow;
          display: block;
          bottom: 15px;
        }
      }
    }

    a.has-children {
      &:hover,
      &:focus,
      &:active {
        + ul.sub-menu {
          padding-top: 20px;
          padding-bottom: 20px;
          max-height: 500px;
          opacity: 1;
        }
      }
    }
    ul.sub-menu {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      background: white;
      z-index: 1;
      margin: 0;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      list-style: none;
      transition: all 0.15s ease-in-out;
      position: absolute;
      left: -20px;
      top: 100%;
      width: 214px;
      padding: 0;
      &:hover,
      &:focus,
      &:active {
        padding-top: 20px;
        padding-bottom: 20px;
        max-height: 500px;
        opacity: 1;
        li {
          display: block;
        }
      }
      li {
        padding: 0px 2px;
        display: none;
      }
      a {
        display: block;
        padding: 10px 64px;
        background-repeat: no-repeat;
        background-size: 36px;
        background-position: 16px 4px;
        margin: 0;
        display: flex;
        align-items: center;
        font-size: rem(14px);
        &:hover,
        &:focus {
          background-color: #f0f0f0;
          text-decoration: underline;
          &:after {
            content: none;
          }
        }
      }

      a.car {
        background-image: url('~assets/images/marketing-menu/navigation_dropdown_car_icon60x60.png');
      }

      a.house {
        background-image: url('~assets/images/marketing-menu/navigation_dropdown_house_icon_60x60.png');
      }

      a.contents {
        background-image: url('~assets/images/marketing-menu/navigation_dropdown_contents_icon_60x60.png');
      }

      a.travel {
        background-image: url('~assets/images/marketing-menu/navigation_dropdown_travel_icon_60x60.png');
      }

      a.business {
        background-image: url('~assets/images/marketing-menu/navigation_dropdown_business-icon_60x60.png');
      }

      a.all {
        background-image: url('~assets/images/marketing-menu/navigation_dropdown_all_insurance_icon_60x60.png');
      }
    }
  }

  #marketing-header-links {
    display: flex;
    align-items: center;
    min-height: 54px;
    float: right;
    font-size: rem(14px);
    // margin-top: 20px;
    // #search-container {
    //   display: flex;
    //   align-items: center;
    //   overflow: hidden;

    //   &.open #search-input {
    //     width: 92px;
    //   }
    //   #search-input {
    //     width: 0px;
    //     border: none;
    //     transition: width 0.25s ease-in-out;
    //     outline: none;
    //   }
    //   #search-link {
    //     text-decoration: none;
    //     margin-right: 10px;
    //     font-weight: 200;
    //     border-bottom: none;
    //     display: block;
    //     i {
    //       font-size: rem(18px);
    //     }
    //   }
    // }

    > a {
      font-size: rem(12px);
      line-height: 15px;
      height: 20px;
      text-decoration: none;
      margin-right: 0px;
      border-bottom: 0px;
      padding-left: 10px;
      font-weight: 400;
      border-left: 1px solid $primary;
      padding-left: 10px;
      padding-right: 10px;
      &:first-child {
        padding-left: 0px;
        padding-right: 10px;
        border-left: none;
      }
      &:last-child {
        padding-right: 0px;
      }

      &:before,
      &:after {
        content: none;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
        opacity: 1;
      }
    }
  }
}
