$brand: tmi;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

.tooltip-container {
  position: relative;
  z-index: 1;
  .tippy-popper {
    transform: none !important;
    position: relative !important;
    clear: both;
    margin: $gap-large 0;
    width: 100%;
    border: none;
    padding: none;
    background: inherit !important;
    outline: none;
    @include tablet {
      max-width: $question-width;
    }
  }
}

.tooltip-icon {
  > div > a {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: absolute;
    overflow: hidden;
    top: -10px;
    border-bottom: 0px;
    right: -15px;
    @include tablet {
      right: initial;
      width: auto;
      height: auto;
    }
  }

  .tippy-active a i.md-icon,
  &:hover a i.md-icon,
  &:focus a i.md-icon,
  &:active a i.md-icon {
    color: $yellow;
  }

  i.md-icon {
    color: $secondary;
    width: 26px;
  }
}

body {
  .tippy-popper {
    margin: $gap-large 0;
    max-width: 400px;
    background: white !important;
    border: 1px solid $grey;
    border-radius: 3px;
    padding: $gap-small;
    opacity: 1;
    transition-duration: 1ms !important;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .tippy-popper .tippy-tooltip.light-theme {
    border-left: 8px solid $yellow;
    color: $tippy-font-color;
    padding-left: $gap-normal;
    transition-duration: 1ms !important;
  }

  .tippy-popper [data-state='hidden'] {
    display: none;
  }

  .tippy-popper [data-state='visible'] {
    display: block;
  }

  .tippy-backdrop {
    transition-duration: 1ms !important;
  }
  .tippy-content {
    transition-duration: 1ms !important;
  }
  .tippy-content > div {
    padding-right: 25px;
  }
  .tooltip-icon-close {
    position: absolute;
    right: 10px;
    top: 10px;
    border-bottom: 0;
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
  .tippy-arrow {
    position: absolute;
    top: 0px !important;
    left: calc(50% - 32px);
    &:after,
    &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-bottom-color: $white;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-bottom-color: $grey;
      border-width: 11px;
      margin-left: -11px;
    }
  }
}
