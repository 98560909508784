// @mixin font-size($sizeValue: 1, $extra: '') {
//   font-size: $sizeValue * $font-size-base unquote($extra);
//   font-size: unquote($sizeValue + 'rem');
// }

// @mixin line-height($sizeValue: 1, $extra: '') {
//   line-height: $sizeValue * $font-size-base unquote($extra);
//   line-height: unquote($sizeValue + 'rem');
// }

@mixin brand($brands...) {
  @if index($brands, $brand) {
    @content;
  }
}

@mixin twr {
  @if ($brand == 'twr') {
    @content;
  }
}

@mixin tmi {
  @if ($brand == 'tmi') {
    @content;
  }
}

@mixin tsb {
  @if ($brand == 'tsb') {
    @content;
  }
}

@mixin air {
  @if ($brand == 'air') {
    @content;
  }
}

@mixin fill-background-image($url, $color, $hoverColor, $andFill: false) {
  $base-color: str-slice(inspect($color), 2);
  $hover-color: str-slice(inspect($hoverColor), 2);

  @if ($andFill == true) {
    background-image: unquote('url("' + $url + '?stroke=%23' + $base-color + '&fill=%23' + $base-color + '")');
  } @else {
    background-image: unquote('url("' + $url + '?stroke=%23' + $base-color + '")');
  }

  &.active {
    @if ($andFill == true) {
      background-image: unquote('url("' + $url + '?stroke=%23' + $hover-color + '&fill=%23' + $hover-color + '")');
    } @else {
      background-image: unquote('url("' + $url + '?stroke=%23' + $hover-color + '")');
    }
  }
}

/*
* REM
* REM
*/
$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
  @if $separator == 'comma' or $separator == 'space' {
    @return append($list, null, $separator);
  }

  @if function-exists('list-separator') == true {
    @return list-separator($list);
  }

  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == 'number' and unit($value) == 'rem' and $to == 'px' {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == 'number' and unit($value) == 'px' and $to == 'rem' {
      $result: append($result, $value / $rem-baseline * 1rem, $separator);
    } @else if type-of($value) == 'list' {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == 'map' {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}
