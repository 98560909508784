@import './variables';

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  background: $body-background;
  height: 100%;
  min-height: 100%;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

main {
  display: block;
  flex-grow: 1;
  width: 100%;
}

.element-invisible {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#skip:focus {
  left: 0;
  z-index: 99999;
  width: auto;
  height: auto;
  opacity: 1 !important;
}

#root,
#content {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root > * {
  min-height: 100vh; // Fix bug on IE where footer is not at bottom of page.
}

#content {
  flex-grow: 1;
}

.container {
  max-width: $boxed-width;
  margin: 0 auto;
  position: relative;
}

table {
  width: 100%;
  text-align: left;
  th,
  td {
    border-bottom: 1px solid black;
  }
}

.page-heading2 {
  margin-bottom: $gap-xxxenormous;
  font-weight: $page-heading-2-font-weight;
  @include tablet {
    font-size: rem(38px);
  }
  @include desktop {
    margin-bottom: $gap-question;
    font-size: rem(48px);
  }
}

html.md-no-scroll.md-no-scroll {
  overflow: visible;
}

#full-page-error {
  text-align: center;
  padding: 0px $gap-normal;
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}

fieldset {
  padding: 0;
  border: none;
  legend {
    display: none;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

// for google analytics
.md-icon,
button > span,
button > i,
a > span,
a > i {
  pointer-events: none !important;
}

// .md-selection-control-input:focus {
//   visibility: visible;
//   margin-top: 5px;
//   margin-left: 4px;
//   width: 16px;
//   height: 18px;
// }

.grecaptcha-badge {
  display: none !important;
}
