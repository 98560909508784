$brand: tmi;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

.blocker-form-message-container {
  margin-top: $gap-large;
  margin-bottom: $gap-large;
  background-color: $blocker-background-color;
  padding: $gap-grid;
  position: relative;
  border: 1px solid $blocker-border-color;
  @include desktop {
    padding: $gap-large;
  }

  &.blocker-form-refer-container {
    border: 2px solid $blocker-refer-border-color;
    background-color: $blocker-refer-background-color;
    > p {
      color: $blocker-refer-heading-color;
    }
  }

  &.blocker-form-error-container {
    border: 2px solid $blocker-decline-border-color;
    background-color: $blocker-decline-background-color;
    > p {
      color: $blocker-decline-heading-color;
    }
  }

  > p,
  > span {
    font-size: rem(16px);
    line-height: rem(24px);
    font-weight: $body-font-weight;
  }

  > p {
    font-weight: bold;
    margin-bottom: $gap-small;
  }
}
