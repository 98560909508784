@import './variables';
@import './media-queries';

body {
  .material-icons.md-icon {
    @media screen and (min-width: 1025px) {
      font-size: rem(24px);
    }
  }

  .md-btn {
    .md-ink-container {
      display: none !important;
    }
  }

  .md-btn--wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: $gap-large 0;

    .md-btn {
      width: auto;
    }
  }

  .md-btn:disabled {
    cursor: default !important;

    > span {
      outline: none !important;
    }

    &:not(.pending) {
      color: $grey !important;
      background-color: $white !important;
      border-color: $grey !important;

      .md-icon {
        color: $grey;
      }
    }
  }

  .md-btn {
    > span {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin-left: $gap-small;
        transition: none;
      }
    }
  }

  .md-btn--text {
    height: auto;
  }

  .md-btn--flat {
    min-height: 50px;
    font-weight: $button-font-weight;
    text-transform: none;

    @include desktop {
      height: 50px;
    }
  }

  .md-btn--primary {
    height: 50px;
    background-color: $primary-button-background-color;
    color: $primary-button-text-color;
    border-color: $primary-button-border-color;

    @include desktop {
      height: 50px;
    }
  }

  .md-btn--outline {
    cursor: pointer;
    font-weight: $button-font-weight;
    transition: all 0.25s ease-in-out;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    @include tablet {
      min-height: 50px;
      /* height - 1 from min-height for vertical alignment IE11 */
      height: 49px;
    }
    div,
    label {
      cursor: pointer;
    }

    label,
    i {
      min-height: 20px;
    }

    @include tablet {
      width: 292px;
    }
  }

  .md-btn--outline--icon {
    height: 140px;
    padding-top: 75px;
    background-repeat: no-repeat !important;
    background-position: top !important;
  }

  .md-btn--outline--inline-icon {
    text-align: left;
    justify-content: space-between;
    padding-right: $gap-xenormous;
    @include desktop {
      padding-right: $gap-enormous;
    }
    i.md-icon {
      position: absolute;
      top: 50%;
      margin-top: -10px;
      right: $gap-small;
      color: inherit;
      transition: none;
    }
  }

  .md-btn > span,
  .md-btn .md-btn--outline-text {
    outline: 1px dotted transparent;
    outline-offset: 3px;
  }

  .md-btn--outline-primary {
    border: 1px solid;
    background-color: $primary-outline-button-background-color;
    color: $primary-outline-button-text-color;
    border-color: $primary-outline-button-border-color;

    &:focus {
      .md-btn--outline-text,
      > span {
        outline-color: $primary-outline-button-text-color;
      }
    }

    &:hover:not(.md-radiobutton),
    &:focus:not(.md-radiobutton),
    &:active:not(.md-radiobutton),
    &.pending:not(.md-radiobutton) {
      background-color: $primary-outline-button-background-color--active;
      color: $primary-outline-button-text-color--active;
      border-color: $primary-outline-button-border-color--active;

      &:focus {
        .md-btn--outline-text,
        > span {
          outline-color: $primary-outline-button-text-color--active;
        }
      }
    }
  }

  .md-btn--outline-tertiary {
    border: 1px solid;
    background-color: $tertiary-outline-button-background-color;
    color: $tertiary-outline-button-text-color;
    border-color: $tertiary-outline-button-border-color;

    &:focus {
      .md-btn--outline-text,
      > span {
        outline-color: $tertiary-outline-button-text-color;
      }
    }

    &:hover,
    &:focus,
    &:active,
    &.pending {
      background-color: $tertiary-outline-button-background-color--active;
      border-color: $tertiary-outline-button-border-color--active;
      color: $tertiary-outline-button-text-color--active;

      &:focus {
        .md-btn--outline-text,
        > span {
          outline-color: $tertiary-outline-button-text-color--active;
        }
      }
    }
  }

  .md-btn--outline-white {
    background-color: $white;
    border: 1px solid grey;
    color: $primary;

    &:hover,
    &:active,
    &:focus {
      border-color: $primary;
      box-shadow: 0px 2px 4px 1px rgba($primary, 0.5);
    }

    &:focus {
      .md-btn--outline-text {
        outline: 1px dotted $primary;
      }
    }
  }

  .md-btn--small {
    min-height: 40px;
    font-size: rem(16px);
    font-weight: $button-font-weight;
    padding: $gap-xsmall $gap-small;

    @include desktop {
      height: 40px;
    }

    .md-icon {
      margin-left: $gap-small;
      font-size: rem(20px);
    }
  }
  .md-selection-control-group,
  .md-selection-control-container {
    padding: 0;
    position: relative;
    // .md-selection-control-input {
    //   visibility: visible;
    //   position: absolute;
    //   left: -9999px;
    // }

    // default
    label.md-selection-control-label div.md-selection-control-toggle + span {
      font-weight: $button-font-weight;
      transition: none;
      > .md-icon {
        transition: none;
      }
    }

    // hover
    label.md-selection-control-label:not(.md-text--disabled):hover div.md-selection-control-toggle {
      .md-icon {
        color: $md-selection-control-button-color;
        transition: none;
      }
      + span {
        color: $md-selection-control-button-color;
        a {
          color: $md-selection-control-button-color;
        }
      }
    }

    // active
    label.md-selection-control-label div.md-selection-control-toggle.md-text--theme-secondary {
      transition: none;
      > .md-icon {
        color: $md-selection-control-button-color;
        transition: none;
      }
      + span {
        color: $md-selection-control-button-color;
        a {
          color: $md-selection-control-button-color;
        }
      }
    }
  }

  .md-selection-control-container {
    height: auto;
    margin-bottom: $gap-normal;

    .md-selection-control-group {
      padding: 0;
    }

    .md-btn {
      padding: 0;
      height: auto;
      width: auto;
      margin-right: $gap-normal;

      &.md-text--secondary {
        color: $md-primary-color;
      }
    }

    .md-icon {
      display: block;
    }

    .md-selection-control-label {
      display: flex;
      align-items: center;

      > span {
        display: block;
        min-height: 28px;
      }
    }

    .md-selection-control-toggle:focus {
      outline: 1px dotted $md-secondary-color;
      outline-offset: 3px;
    }
  }

  .md-btn--add-accordion {
    background-color: rgba($md-secondary-color, 0.1);
    color: $primary;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: rem(24px);
    font-weight: $body-font-weight-light;

    .md-icon {
      font-size: rem(32px);
    }

    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
    }
  }
}
