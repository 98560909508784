$brand: tmi;
@import '~common/styles/variables';

.closable-dialog-container {
  &--close-button {
    position: absolute;
    right: 5px;
    top: 5px;
    border-bottom: none;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline-offset: -2px !important;

    .md-icon {
      color: $md-primary-color;
      font-size: rem(25px);
      &:hover {
        color: $md-secondary-color;
      }
    }
  }
}
