@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('~assets/fonts/MaterialIcons/MaterialIcons-Regular.woff2') format('woff2'),
    url('~assets/fonts/MaterialIcons/MaterialIcons-Regular.woff') format('woff'),
    url('~assets/fonts/MaterialIcons/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-100.eot');
  src: url('~assets/fonts/rawline/rawline-100.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-100.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-100.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-100.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-100.svg') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-100i.eot');
  src: url('~assets/fonts/rawline/rawline-100i.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-100i.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-100i.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-100i.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-100i.svg') format('svg');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-200.eot');
  src: url('~assets/fonts/rawline/rawline-200.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-200.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-200.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-200.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-200.svg') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-200i.eot');
  src: url('~assets/fonts/rawline/rawline-200i.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-200i.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-200i.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-200i.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-200i.svg') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-300-.eot');
  src: url('~assets/fonts/rawline/rawline-300-.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-300.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-300.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-300.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-300.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-300i.eot');
  src: url('~assets/fonts/rawline/rawline-300i.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-300i.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-300i.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-300i.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-300i.svg') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-400.eot');
  src: url('~assets/fonts/rawline/rawline-400.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-400.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-400.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-400.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-400.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-400i.eot');
  src: url('~assets/fonts/rawline/rawline-400i.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-400i.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-400i.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-400i.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-400i.svg') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-500.eot');
  src: url('~assets/fonts/rawline/rawline-500.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-500.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-500.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-500.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-500.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-500i.eot');
  src: url('~assets/fonts/rawline/rawline-500i.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-500i.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-500i.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-500i.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-500i.svg') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-600.eot');
  src: url('~assets/fonts/rawline/rawline-600.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-600.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-600.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-600.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-600.svg') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-600i.eot');
  src: url('~assets/fonts/rawline/rawline-600i.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-600i.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-600i.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-600i.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-600i.svg') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-700.eot');
  src: url('~assets/fonts/rawline/rawline-700.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-700.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-700.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-700.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-700.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-700i.eot');
  src: url('~assets/fonts/rawline/rawline-700i.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-700i.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-700i.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-700i.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-700i.svg') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-800.eot');
  src: url('~assets/fonts/rawline/rawline-800.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-800.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-800.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-800.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-800.svg') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-800i.eot');
  src: url('~assets/fonts/rawline/rawline-800i.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-800i.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-800i.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-800i.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-800i.svg') format('svg');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-900.eot');
  src: url('~assets/fonts/rawline/rawline-900.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-900.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-900.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-900.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-900.svg') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'rawline';
  font-display: auto;
  src: url('~assets/fonts/rawline/rawline-900i.eot');
  src: url('~assets/fonts/rawline/rawline-900i.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/rawline/rawline-900i.woff2') format('woff2'),
    url('~assets/fonts/rawline/rawline-900i.woff') format('woff'),
    url('~assets/fonts/rawline/rawline-900i.ttf') format('truetype'),
    url('~assets/fonts/rawline/rawline-900i.svg') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Licenz';
  src: url('~assets/fonts/Licenz/Licenz.eot');
  src: url('~assets/fonts/Licenz/Licenz.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/Licenz/Licenz.woff2') format('woff2'), url('~assets/fonts/Licenz/Licenz.woff') format('woff'),
    url('~assets/fonts/Licenz/Licenz.ttf') format('truetype'),
    url('~assets/fonts/Licenz/Licenz.svg#Licenz') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Story Sans';
  src: url('~assets/fonts/StorySans/StorySans-Light.eot');
  src: url('~assets/fonts/StorySans/StorySans-Light.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/StorySans/StorySans-Light.woff2') format('woff2'),
    url('~assets/fonts/StorySans/StorySans-Light.woff') format('woff'),
    url('~assets/fonts/StorySans/StorySans-Light.ttf') format('truetype'),
    url('~assets/fonts/StorySans/StorySans-Light.svg#StorySans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Story Sans';
  src: url('~assets/fonts/StorySans/StorySans-Regular.eot');
  src: url('~assets/fonts/StorySans/StorySans-Regular.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/StorySans/StorySans-Regular.woff2') format('woff2'),
    url('~assets/fonts/StorySans/StorySans-Regular.woff') format('woff'),
    url('~assets/fonts/StorySans/StorySans-Regular.ttf') format('truetype'),
    url('~assets/fonts/StorySans/StorySans-Regular.svg#StorySans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Story Sans';
  src: url('~assets/fonts/StorySans/StorySans-Bold.eot');
  src: url('~assets/fonts/StorySans/StorySans-Bold.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/StorySans/StorySans-Bold.woff2') format('woff2'),
    url('~assets/fonts/StorySans/StorySans-Bold.woff') format('woff'),
    url('~assets/fonts/StorySans/StorySans-Bold.ttf') format('truetype'),
    url('~assets/fonts/StorySans/StorySans-Bold.svg#StorySans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Story Sans';
  src: url('~assets/fonts/StorySans/StorySans-Medium.eot');
  src: url('~assets/fonts/StorySans/StorySans-Medium.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/StorySans/StorySans-Medium.woff2') format('woff2'),
    url('~assets/fonts/StorySans/StorySans-Medium.woff') format('woff'),
    url('~assets/fonts/StorySans/StorySans-Medium.ttf') format('truetype'),
    url('~assets/fonts/StorySans/StorySans-Medium.svg#StorySans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}
