$brand: tmi;
.Layout-Core {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .main-content-wrapper {
    max-width: 860px;
    margin: 0 auto;
  }
}
