$brand: tmi;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

#form-footer {
  z-index: 1;
  padding-top: $gap-xxenormous;
  padding-bottom: $gap-xxenormous;
  background: $grey-light;
  margin-left: -$gap-normal;
  margin-right: -$gap-normal;
  position: relative;
  overflow: hidden;
  @include desktop {
    margin-left: -50vw;
    margin-right: 0;
    left: 50%;
    width: 100vw;
  }

  .form-footer--contents {
    @include tablet {
      padding-right: 55px;
    }
    @include desktop {
      margin-left: 115px;
      width: $question-width-tablet;
      padding-right: 0;
    }
    @include large-desktop {
      margin-left: 105px;
      padding-right: 0px;
      width: $question-full-width;
    }
    // margin: 0 auto;
  }

  .full-width-block-content {
    @include tablet {
      margin-left: 55px;
      width: $question-width;
    }
    @include desktop {
      margin-left: 105px;
    }
  }
  .form-footer--button-wrapper {
    display: flex;
    align-items: center;
    margin-left: -6px;
    margin-right: -6px;
    flex-wrap: wrap;
  }

  .form-footer--button-right {
    justify-content: flex-end;
    .md-btn {
      @include tablet {
        margin-right: 6px;
      }
    }
  }

  .form-footer--button-wrapper {
    .md-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      @include tablet {
        max-width: calc(52% - 12px);
        width: auto;
      }
    }

    @include tablet {
      margin-left: 60px;
    }
    @include desktop {
      margin-left: 0;
    }
  }

  div.md-btn--outline {
    display: flex;
    width: 30%;
    justify-content: flex-start;
    cursor: default;
    @include up-to-tablet {
      margin-top: $gap-large;
      order: 2;
    }
  }

  .back-btn-hidden {
    opacity: 0;
    visibility: hidden;
  }

  #btnBackPage {
    margin-left: 0px;
    margin-right: 6px;
    padding-left: 0;
    border-bottom: none;
    transition: none;
    text-align: left;

    label {
      border-bottom: 1px solid $link-color;
      position: relative;
      top: -5px;
      margin-right: 5px;
    }

    &:hover,
    &:focus,
    &:active {
      label,
      .md-icon {
        border-bottom-color: transparent;
        color: $md-secondary-color;
      }
    }

    .md-icon {
      margin-left: 0;
      margin-right: $gap-small;
    }
  }

  #btnSubmitPage {
    span {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
    }
    span label {
      display: block;
      text-align: left;
      @include tablet {
        flex-grow: 1;
      }
    }
  }
}
